<template>
    <div class="all">
        <Home2Header active-index="/about"></Home2Header>
        <AboutHeader2 active-index2="/history"></AboutHeader2>
        <div class="main" style="">
          <i class="el-icon-location-outline" style="font-size: 30px"></i>
          <v-timeline align-top>
            <v-timeline-item
                style="text-align: left"
                small>
              <h3>2016.8</h3>
              <span>成立广州双动科技有限公司</span>
              <el-image :src="require('../../assets/About/round1.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-left: 20px;"/>
            </v-timeline-item>

            <v-timeline-item
                class="text-right"
                style="text-align: right"
                small>
              <h3>2016.9</h3>
              <el-image :src="require('../../assets/About/round2.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-right: 20px;"/>
              <span>成功推出高尔夫体感高尔夫之旅</span>
            </v-timeline-item>

            <v-timeline-item
                style="text-align: left"
                small>
              <h3>2016.11</h3>
              <span>正式成立智能穿戴项目部</span>
              <el-image :src="require('../../assets/About/round3.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-left: 20px;"/>
            </v-timeline-item>

            <v-timeline-item
                class="text-right"
                style="text-align: right"
                small>
              <h3>2017.4</h3>
              <el-image :src="require('../../assets/About/round4.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-right: 20px;"/>
              <span>成功推出体感平台体感健身房</span>
            </v-timeline-item>

            <v-timeline-item
                style="text-align: left"
                small>
              <h3>2017.7</h3>
              <span>成功推出体感热舞</span>
              <el-image :src="require('../../assets/About/round5.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-left: 20px;"/>
            </v-timeline-item>

            <v-timeline-item
                class="text-right"
                style="text-align: right"
                small>
              <h3>2017.8</h3>
              <el-image :src="require('../../assets/About/round6.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-right: 20px;"/>
              <span>体感健身房正式在中国联通<br/>全国IPTV上线运营</span>
            </v-timeline-item>

            <v-timeline-item
                style="text-align: left"
                small>
              <h3>2018.4</h3>
              <span>成功与中国国安广电签署《电视游戏合作协议》</span>
              <el-image :src="require('../../assets/About/round7.png')" lazy style="vertical-align:middle;height: 50px;width: 50px;margin-left: 20px;"/>
            </v-timeline-item>

          </v-timeline>

          <p>至今</p>
          </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Home2Header from "../../components/Home2Header";
    import AboutHeader2 from "../../components/AboutHeader2";
    import Footer from "../../components/Footer";

    export default {
        name: "History",
        data() {
            return {
            }
        },
        methods: {},
        components: {
            Home2Header, AboutHeader2, Footer
        },
        props: {
            activeIndex: {
                type: String
            },
            activeIndex2: {
                type: String
            }
        },
    }
</script>

<style scoped>
    .all{
        min-width:960px;
    }
    .main {
        margin: 60px 0 50px 0;
    }
</style>